import React, { useState, useEffect } from "react";
import Barcode from "react-barcode";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { AES, enc } from "crypto-js";
import { BASE_URL } from "../CommonApi";
import "./ItemDetails.css";
import ItemUpdate from "./ItemUpdate";
import JsBarcode from 'jsbarcode';
import { CiEdit } from "react-icons/ci";

function ItemDetails(props) {
  const handleCancleClick = () => {
    const confirmCancel = window.confirm('Are you sure Leave this page?');
    if (confirmCancel) {
      // Code to handle cancellation
      props.handleCancle();
    } else {
      // Code to handle cancellation not confirmed
      console.log('Cancellation not confirmed');
    }
  };

  const { Itemid } = props;
  const [itemId1, setItemId1] = useState(Itemid);
  const [selectedTab, setSelectedTab] = useState("overview"); // Initial tab

  const [quantity, setQuantity] = useState("");
  const [barcodes, setBarcodes] = useState([]);
  const [showDetails, setShowDetails] = useState(true)
  const [showEditPage, setEditPage] = useState(false)
  const [editId, setEditId] = useState("")
  const [companyName, setCompanyName] = useState("")
  const jwtToken = localStorage.getItem("jwtToken");
  const userId = localStorage.getItem("userId");

  const apiUrl = BASE_URL + `/fetchUserProfile`;
  const fetchData = () => {
    const encryptedUniqueId = localStorage.getItem("uniqueId1");
    const decryptedUniqueId = AES.decrypt(
      encryptedUniqueId,
      "1234567812345678"
    ).toString(enc.Utf8);

    fetch(apiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({ uniqueId: decryptedUniqueId, userId: userId }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          const portfolioItem = data.responseMessage;
          setCompanyName(portfolioItem.companyName);
        } else {
          console.error("Error fetching portfolio data");
        }
      })
      .catch((error) => {
        console.error("Error fetching portfolio data:", error);
      });
  };

  useEffect(() => {
    fetchData();
  }, [])

  const handleEditOpen = (id) => {
    setEditId(id)
    setEditPage(true)
    setShowDetails(false)
  }

  const handleCancel = () => {
    setEditPage(false)
    setShowDetails(true)
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    const parsedQuantity = parseInt(quantity, 10);
    if (isNaN(parsedQuantity) || parsedQuantity <= 0) {
      alert("Please enter a valid positive number for quantity");
      return;
    }

    // Generate an array of barcodes based on the quantity
    const generatedBarcodes = Array.from(
      { length: parsedQuantity },
      (_, index) => itemId1
    );
    setBarcodes(generatedBarcodes);
  };

  const handleTabClick = (tab) => {
    setSelectedTab(tab);
    setQuantity("");
    setBarcodes([]);
  };

  const [barCodeName, setBarCodeName] = useState();
  const [barCodeSalesPrice, setBarCodeSalesPrice] = useState();
  const [itemData, setItemData] = useState({});
  const [itemData1, setItemData1] = useState([]);

  // Overview
  useEffect(() => {
    const apiUrl = BASE_URL + `/getItemData/${itemId1}`;
    fetch(apiUrl, {
      method: "Post",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify(),
    })
      .then((response) => response.json())
      .then((data) => {
        setItemData(data.data);
      })
      .catch((error) => console.error("Error fetching overview data:", error));
  }, [itemId1]);

  // leftbar
  useEffect(() => {
    const encryptedUniqueId = localStorage.getItem("uniqueId1");
    const decryptedUniqueId = AES.decrypt(
      encryptedUniqueId,
      "1234567812345678"
    ).toString(enc.Utf8);
    const postApiUrl = BASE_URL + `/getAllItemData`;
    fetch(postApiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({ uniqueId: decryptedUniqueId ,vendorId:""}),
    })
      .then((response) => response.json())
      .then((data) => {
        setItemData1(data.responseMessage);
      })
      .catch((error) => {
        console.error("Error making POST request:", error);
      });
  }, []);

  const handleItemChange = (id) => {
    setItemId1(id);
    setSelectedTab("overview");
    setBarcodes([]);
  };

  const generateBarcode = (barcodeData) => {
    JsBarcode("#barcode", barcodeData, {
      format: "CODE128",
      displayValue: true,
      fontSize: 14,
      textMargin: 0,
    });
  };

  const barCodeHandle = () => {
    const apiUrl = BASE_URL + `/getItemData/${Itemid}`;
    fetch(apiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        const itemData = data.data;
        setBarCodeName(itemData.name);
        console.log("itemData.name : ", itemData.name);
        setBarCodeSalesPrice(itemData.salesPrice);
        console.log("itemData.salesPrice : ", itemData.salesPrice);
        generateBarcode(itemData.barcode); // Assuming barcode is part of item data
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  const handlePrint = () => {
    const printableContent = document.getElementById("printable-content");
    if (printableContent) {
      const printWindow = window.open();
      printWindow.document.write(`
        <html>
          <head>
            <!-- Add your custom styles for printing here -->
            <style>
              @media print {
                .printable-content {
                  display: flex;
                  flex-direction: column;
                  align-items: flex-start;
                  width: 100%;
                }
                .barcode-container {
                  margin: 12px 0;
                  text-align: left;
                  width: 100%;
                }
                  body{
                  font-family: "Nunito", sans-serif;
                  margin:0px;
                  color:black;
                  }
              }
            </style>
          </head>
          <body>
            ${printableContent.outerHTML} <!-- Use the outerHTML to include the entire element -->
          </body>
        </html>
      `);
      printWindow.document.close();
      printWindow.onafterprint = () => {
        printWindow.close();
        printWindow.onafterprint = null;
      };
      printWindow.print();
    }
  };



  return (
    <>
      {showDetails && (
        <div>
          <div
            className="d-flex justify-content-between mt-3"
            style={{ padding: "5px 5px 0px 5px" }}
          >
            <div className="d-flex align-items-center ">
              <div
                className="cursor-pointer d-flex justify-content-center align-items-center"
                style={{
                  backgroundColor: "white",
                  borderRadius: "50%",
                  height: "35px",
                  width: "38px",
                  cursor: "pointer"
                }}
              >
                <ArrowBackIosIcon
                  width={2}
                  onClick={handleCancleClick}
                  className="arrow-child"
                />
              </div>
              <div className="headitems ml-2">
                <h5
                  className="ml-1 mb-0"
                  style={{ letterSpacing: "1px", color: "#086bd5de" }}
                >
                  All Items
                </h5>
                <p className="ml-1 mb-0 text-muted">{itemData1.length} Items</p>
              </div>
            </div>
            <div className="add-item pr-2">
            </div>
          </div>
          <div
            className="card border-2 items-border mt-3"
            style={{ borderRadius: "15px", height: "auto", marginBottom: "0px" }}
          >
            {/* <hr /> */}
            <div className="custom-itemdetails-container">
              <div className="left-items custom-scrollbar">
                <div>
                  <div className="text-left" style={{ cursor: "pointer" }}>
                    {itemData1.map((item, index) => (
                      <React.Fragment key={index}>
                        <div
                          className="items-row"
                          onClick={() => {
                            handleItemChange(item.id);
                            setSelectedTab("overview");
                            setQuantity("");
                          }}
                          style={{ cursor: "pointer" }}
                        >
                          <b
                            style={{
                              fontSize: "16px",
                              fontWeight: "500",
                              // color: " #575757",
                            }}
                          >
                            {item.name}
                          </b>
                          <div className="d-flex justify-content-between mt-1">
                            <p
                              className="text-muted"
                              style={{
                                fontSize: "14px",
                                fontWeight: "600",
                                marginBottom: "0px",
                              }}
                            >
                              {item.type}
                            </p>
                            <span className="fs-1 text-muted">{item.unit}</span>
                          </div>
                        </div>
                        {index < itemData1.length - 0 && (
                          <div className="horizontal-line"></div>
                        )}
                      </React.Fragment>
                    ))}
                  </div>
                </div>
              </div>

              <div className="middle-line"></div>

              <div className="right-items">
                {/* Top Template Bar */}
                <div className="top-item-bar">
                  <ul className="nav nav-tabs-new d-flex">
                    <h5
                      className="flex-grow-1 mb-0 ml-1"
                      style={{ color: " #3C7BD4" }}
                    >
                      {itemData.name}
                    </h5>
                    <li className="nav-item mr-1 cursor-pointer">
                      <a
                        className="nav-link"
                        href="#"
                        role="button"
                        style={{
                          borderRadius: "10px",
                          border: "1.4px solid #c1bebe",
                        }}
                        onClick={() => handleEditOpen(itemData.id)}
                      >
                        <CiEdit size={18} /> {/* Set the size to 24 */}
                        <span>Edit</span>{" "}
                      </a>
                    </li>
                  </ul>
                  <ul className="nav nav-tabs-new">
                    <li
                      className="nav-item p-2"
                      style={{
                        fontSize: "16px",
                        margin: "0.7rem 0.5rem 0.7rem 0.5rem",
                      }}
                    >
                      <span
                        className={`cursor-pointer border-0 ${selectedTab === "overview"
                          ? "btn-item-link text-primary"
                          : "text-muted"
                          }`}
                        onClick={() => handleTabClick("overview")}
                      >
                        OVERVIEW
                      </span>
                    </li>
                    <li
                      className="nav-item p-2"
                      style={{
                        fontSize: "16px",
                        margin: "0.7rem 0.5rem 0.7rem 0.8rem ",
                      }}
                    >
                      <span
                        className={`cursor-pointer  border-0 ${selectedTab === "comments"
                          ? "btn-item-link text-primary"
                          : "text-muted"
                          }`}
                        onClick={() => handleTabClick("comments")}
                        style={{ fontSize: "16px" }}
                      >
                        BARCODE
                      </span>
                      {/* </a> */}
                    </li>
                    {/* <li
                      className="nav-item p-2"
                      style={{
                        fontSize: "16px",
                        margin: "0.7rem 0.5rem 0.7rem 0.8rem ",
                      }}
                    >
                      <span
                        className={`cursor-pointer border-0 ${selectedTab === "sales"
                            ? "btn-item-link text-primary"
                            : "text-muted"
                          }`}
                        onClick={() => handleTabClick("sales")}
                        style={{ fontSize: "16px" }}
                      >
                        HISTORY
                      </span>
                    </li> */}
                  </ul>
                  <div className="horizontal-line"></div>
                </div>

                <div className="custom-item-container custom-scrollbar">
                  {selectedTab === "overview" && (
                    <>
                      <div className="second-col">
                        <div className="row">
                          <div className="col-md-6">
                        <h5 className=" mt-3" >ITEM OVERVIEW</h5>
                        <div className="row mg-t-15 pt-2 align-items-center mg-b-20">
                          <div className="col-md-4">
                            <label className="form-label required mg-b-0 text-muted">
                              Item Type :
                            </label>
                          </div>
                          <div>
                            <label
                              className="form-label required mg-b-0"
                              style={{ color: " #575757" }}
                            >
                              {itemData.type || "-"}
                            </label>
                          </div>
                        </div>

                        <div className="row mg-t-15 pt-2 align-items-center mg-b-20">
                          <div className="col-md-4">
                            <label className="form-label required mg-b-0 text-muted">
                              Unit :
                            </label>
                          </div>
                          <div>
                            <label
                              className="form-label required mg-b-0"
                              style={{ color: " #575757" }}
                            >
                              {itemData.unit || "-"}
                            </label>
                          </div>
                        </div>

                        <div className="row mg-t-15 pt-2 align-items-center mg-b-20">
                          <div className="col-md-4">
                            <label className="form-label required mg-b-0 text-muted">
                              Tax Preference :
                            </label>
                          </div>
                          <div>
                            <label
                              className="form-label required mg-b-0"
                              style={{ color: " #575757" }}
                            >
                              {itemData.taxPreference || "-"}
                            </label>
                          </div>
                        </div>

                        <div className="row mg-t-15 pt-2 align-items-center mg-b-20">
                          <div className="col-md-4">
                            <label className="form-label required mg-b-0 text-muted">
                              Reason :
                            </label>
                          </div>
                          <div>
                            <label
                              className="form-label required mg-b-0"
                              style={{ color: " #575757" }}
                            >
                              {itemData.exemptionReason || "-"}
                            </label>
                          </div>
                        </div>
                        </div>
                        {/* <hr /> */}
                        <div className="col-md-6">
                        <h5 className="mt-2">INVENTORY INFORMATION</h5>
                        <div className="row mg-t-15 pt-2 align-items-center mg-b-20">
                          <div className="col-md-4">
                            <label className="form-label required mg-b-0 text-muted">
                              Available Stock :
                            </label>
                          </div>
                          <div>
                            <label
                              className="form-label required mg-b-0"
                              style={{ color: " #575757" }}
                            >
                              {itemData.openingStock || "-"}
                            </label>
                          </div>
                        </div>
                        <div className="row mg-t-15 pt-2 align-items-center mg-b-20">
                          <div className="col-md-4">
                            <label className="form-label required mg-b-0 text-muted">
                              Reorder Point :
                            </label>
                          </div>
                          <div>
                            <label
                              className="form-label required mg-b-0"
                              style={{ color: " #575757" }}
                            >
                              {itemData.reorderPoint || "-"}
                            </label>
                          </div>
                        </div>
                        </div>
                       
                        </div>

                        {/* <hr /> */}
                        <div className="row">
                          <div className="col-md-6">
                        <h5 className=" mt-2">SALES INFORMATION</h5>
                        <div className="row mg-t-15 pt-2 align-items-center mg-b-20">
                          <div className="col-md-4">
                            <label className="form-label required mg-b-0 text-muted">
                              Sales Price :
                            </label>
                          </div>
                          <div>
                            <label
                              className="form-label required mg-b-0"
                              style={{ color: " #575757" }}
                            >
                             ₹ {itemData.salesPrice || "-"}
                            </label>
                          </div>
                        </div>
                        <div className="row mg-t-15 pt-2 align-items-center mg-b-20">
                          <div className="col-md-4">
                            <label className="form-label required mg-b-0 text-muted">
                              Sales Account :
                            </label>
                          </div>
                          <div>
                            <label
                              className="form-label required mg-b-0"
                              style={{ color: " #575757" }}
                            >
                              {itemData.account || "-"}
                            </label>
                          </div>
                        </div>
                        <div className="row mg-t-15 pt-2 align-items-center mg-b-20">
                          <div className="col-md-4">
                            <label className="form-label required mg-b-0 text-muted">
                              Sales Description :
                            </label>
                          </div>
                          <div>
                            <label
                              className="form-label required mg-b-0"
                              style={{ color: " #575757" }}
                            >
                              {itemData.description || "-"}
                            </label>
                          </div>
                        </div>
                        </div>
                       
                        <div className="col-md-6">
                        <h5 className="mt-2">PURCHASE INFORMATION</h5>

                        <div className="row mg-t-15 pt-2 align-items-center mg-b-20">
                          <div className="col-md-4">
                            <label className="form-label required mg-b-0 text-muted">
                              Cost Price :
                            </label>
                          </div>
                          <div>
                            <label
                              className="form-label required mg-b-0"
                              style={{ color: " #575757" }}
                            >
                             ₹ {itemData.purchasePrice || "-"}
                            </label>
                          </div>
                        </div>

                        <div className="row mg-t-15 pt-2 align-items-center mg-b-20">
                          <div className="col-md-4">
                            <label className="form-label required mg-b-0 text-muted">
                              Purchase Amount :
                            </label>
                          </div>
                          <div>
                            <label
                              className="form-label required mg-b-0"
                              style={{ color: " #575757" }}
                            >
                              {itemData.accountP || "-"}
                            </label>
                          </div>
                        </div>

                        <div className="row mg-t-15 pt-2 align-items-center mg-b-20">
                          <div className="col-md-4">
                            <label className="form-label required mg-b-0 text-muted">
                              Cost Description :
                            </label>
                          </div>
                          <div>
                            <label
                              className="form-label required mg-b-0"
                              style={{ color: " #575757" }}
                            >
                              {itemData.descriptionP || "-"}
                            </label>
                          </div>
                        </div>
                        </div>
                        </div>
                      </div>
                    </>
                  )}

                  {selectedTab === "comments" && (
                    <div className="container my-3">
                      <form onSubmit={handleSubmit}>
                        <div className="mb-3">
                          <label
                            htmlFor="exampleInputEmail1"
                            className="form-label"
                          >
                            Enter Quantity
                          </label>
                          <input
                            className="form-control"
                            type="text"
                            placeholder="Enter No. Of Barcode You Want"
                            value={quantity}
                            onChange={(e) => setQuantity(e.target.value)}
                            style={{ borderRadius: "7px", height: "40px" }}
                          />
                        </div>
                        <button
                          type="submit"
                          className="btn btn-primary"
                          onClick={barCodeHandle}
                        >
                          Submit
                        </button>
                        {barcodes.length > 0 && (
                          <button className="btn btn-success ml-2" onClick={handlePrint}>
                            Print Barcodes
                          </button>
                        )}
                      </form>

                      {/* Barcode */}
                      {barcodes.length > 0 && (
                        <div>
                          <div
                            id="printable-content"
                            className="mt-1"
                            style={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}
                          >
                            {barcodes.map((barcode, index) => (
                              <div
                                className="my-1 border border-1"
                                key={index}
                                style={{marginTop:"10px", textAlign: "center",display: "inline-block",pageBreakAfter: "always" }}
                               >
                                <h4 style={{ margin: 0 }}>{companyName}</h4>
                                <h6 style={{ margin: 0 }}>{barCodeName}</h6>
                                <h5 style={{ margin: 0 }}><b>Price: </b>₹ {barCodeSalesPrice}</h5>
                                {/* <Barcode style={{ margin: 0 }} value={barcode} height={30} width={3} displayValue={false} />                                 */}
                                <div style={{ position: "relative", display: "inline-block" }}>
                                  <Barcode value={barcode} height={30} width={3} displayValue={false} />
                                  <span style={{ position: "absolute", bottom: 0, right: "10px",top : "2.5rem", fontSize: "0.8em" }}>
                                    {barcode}
                                  </span>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      )}
                    </div>
                  )}

                  {selectedTab === "sales" && (
                    <div className="container my-3 border border-2">
                      <table className="table">
                        <thead>
                          <tr>
                            <th scope="col">DATE</th>
                            <th scope="col">DETAILS</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>18-11-23</td>
                            <td>added by demo1@gmail.com</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {showEditPage && (
        <ItemUpdate id={editId} handleCancle={handleCancel} />
      )}
    </>
  );
}

export default ItemDetails;
