import React, { useState, useEffect } from "react";
import $ from "jquery";
import "datatables.net";
import "datatables.net-dt/css/jquery.dataTables.css";
import swal from "sweetalert";
import { AES, enc } from "crypto-js";
import { BASE_URL } from "../CommonApi";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import Select from "react-select";
import Alert from "@mui/material/Alert";

function BankUpdate(props) {
  const { id } = props;
  const encryptedUniqueId = localStorage.getItem("uniqueId1");
  const decryptedUniqueId = AES.decrypt(
    encryptedUniqueId,
    "1234567812345678"
  ).toString(enc.Utf8);

  const [fetchedAccountType, setFetchedAccountType] = useState("");
  const [formData, setFormData] = useState({
    accountType: "",
    name: "",
    accountCode: "",
    currency: "",
    accountNumber: "",
    bankName: "",
    ifsc: "",
    description: "",
    upiId:"",
    primaryAccount: "NO",
    branchName:""
  });

  const [showBank, setShowBank] = useState(formData.accountType === "Bank");
  const [showCreditCard, setShowCreditCard] = useState(formData.accountType === "Credit-Card");

  const handleCancleClick = () => {
    const confirmCancel = window.confirm("Are you sure Leave this page?");
    if (confirmCancel) {
      // Code to handle cancellation
      props.handleCancle();
      $("#DataTables_Table_0").DataTable();
    } else {
      // Code to handle cancellation not confirmed
      console.log("Cancellation not confirmed");
    }
  };

  const handleBankSelect = () => {
    setShowBank(true);
    setShowCreditCard(false);
  };

  const handleRadioChange = (e, value) => {
    if (value === "Bank") {
      setShowBank(true);
      setShowCreditCard(false);
    } else if (value === "Credit-Card") {
      setShowCreditCard(true);
      setShowBank(false);
    }

    setFormData({
      ...formData,
      accountType: value,
    });
  };

  const handleCreditCardSelect = () => {
    setShowCreditCard(true);
    setShowBank(false);
  };

  const [errors, setErrors] = useState({});


  const [selectedCurreny, setCurrency] = useState("INR");

  const currencyOptions = [
    { value: "", label: "Select Currency" },
    { value: "INR", label: "INR - Indian Rupee" },
    { value: "AED", label: "AED - United Arab Emirates Dirham" },
    { value: "AUD", label: "AUD - Australian Dollar" },
    { value: "CAD", label: "CAD - Canadian Dollar" },
    { value: "CNY", label: "CNY - Chinese Yuan" },
    { value: "EUR", label: "EUR - Euro" },
    { value: "GBP", label: "GBP - British Pound Sterling" },
    { value: "JPY", label: "JPY - Japanese Yen" },
    { value: "SAR", label: "SAR - Saudi Riyal" },
    { value: "USD", label: "USD - United States Dollar" },
    { value: "ZAR", label: "ZAR - South African Rand" }
  ];

  const handleCurrencyChange = (e) => {
    const selectedValue = e.value;

    setFormData({
      ...formData,
      currency: selectedValue,
    });
  };

  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const [updatedChecked, setUpdatedChecked] = useState(false);
  console.log("updatedChecked : ",updatedChecked);
  const handleCheckboxChange = (event) => {
    const isChecked = event.target.checked;
    const updatedValue = isChecked ? "YES" : "NO";
    console.log("isChecked : ",isChecked);
    setFormData({
      ...formData,
      primaryAccount: updatedValue,
    });
  };

  const [validationMessage, setValidationMessage] = useState("");


  const sendData = async () => {
    const jwtToken = localStorage.getItem("jwtToken");

    let newValidationMessage = "";

    if (!formData.name) {
      newValidationMessage += "Please Enter Account Name.\n";
    }

    setValidationMessage(newValidationMessage);

    if (newValidationMessage) {
      window.scrollTo(0, 0);
      return;
    }

    try {
      const response = await fetch(BASE_URL + `/updateBank/${id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${jwtToken}`,
        },
        body: JSON.stringify({ uniqueId: decryptedUniqueId, ...formData }),
      });
      if (!response.ok) {
        throw new Error("Failed to update data");
      }
      const data = await response.json();
      swal({
        icon: "success",
        title: "Bank Data Updated Successfully",
        text: data.message,
      });
      props.handleCancle();
    } catch (error) {
      swal({
        icon: "error",
        title: "Network Error",
        text: "An error occurred while communicating with the server.",
      });
    }

  };

  const apiUrl = BASE_URL + `/getBankById/${id}`;

  const fetchData = () => {
    const data = {
      uniqueId: decryptedUniqueId,
    };
    const jwtToken = localStorage.getItem("jwtToken");
    fetch(apiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((response) => {
        const Data = response.responseMessage;
        setFormData({
          accountType: Data.accountType,
          name: Data.name,
          accountCode: Data.accountCode,
          currency: Data.currency,
          accountNumber: Data.accountNumber,
          bankName: Data.bankName,
          ifsc: Data.ifsc,
          description: Data.description,
          upiId:Data.upiId,
          primaryAccount: Data.primaryAccount,
          branchName:Data.branchName,
        });
        setFetchedAccountType(Data.accountType);
        setUpdatedChecked(Data.primaryAccount === "YES" ? true : false);
      })

      .catch((error) => {
        console.error("Error making POST request:", error);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <div className="d-flex align-items-center ml-1 mt-4">
        <div
          className="cursor-pointer d-flex justify-content-center align-items-center"
          style={{
            backgroundColor: "white",
            borderRadius: "50%",
            height: "35px",
            width: "38px",
            cursor: "pointer",
          }}
        >
          <ArrowBackIosIcon
            width={2}
            onClick={handleCancleClick}
            className="arrow-child"
          />
        </div>
        <div className="newcustomer-head ml-2">
          <h5
            className="ml-1 mb-0"
            style={{ letterSpacing: "1px", color: "#086bd5de" }}
          >
            Update Bank / Credit Card
          </h5>
        </div>
      </div>
      <div className="card border-0 my-3">
        {validationMessage && (
          <Alert severity="error" className="mt-3">
            {validationMessage.split("\n").map((message, index) => (
              <div key={index}>{message}</div>
            ))}
          </Alert>
        )}{" "}
        <div className="pl-4 pr-2 pt-3">
          <h6 className="mb-0" style={{ color: "#086bd5de" }}>
            BANK INFO
          </h6>
          <div className="row pt-3">
            <div className="col-md-2">
              <label className="form-label">
                <span className="text-muted">Select Account Type*</span>{" "}
              </label>
            </div>
            {/* <div> */}
            <div className="col-lg-1 pr-0">
              <label className="rdiobox">
                <input
                  className="mx-1"
                  type="radio"
                  name="accountType"
                  id="cus_bus"
                  value="Bank"
                  onClick={() => handleBankSelect(true)}
                  checked={formData.accountType === "Bank"}
                  onChange={(e) => handleRadioChange(e, "Bank")}
                />
                <span>Bank</span>
              </label>
            </div>
            {/* </div> */}
            <div className="col-lg-2 pl-0">
              <label className="rdiobox ml-3">
                <input
                  className="mx-1"
                  type="radio"
                  name="accountType"
                  id="cus_indivi"
                  value="Credit-Card"
                  onClick={() => handleCreditCardSelect(false)}
                  checked={formData.accountType === "Credit-Card"}
                  onChange={(e) => handleRadioChange(e, "Credit-Card")}
                />
                <span>Credit Card</span>
              </label>
            </div>
          </div>

          {formData.accountType === "Bank" && (
            <div>
              <div className="row pt-3 d-flex align-items-center">
                <div className="col-md-2">
                  <label className="form-label">
                    <span className="text-muted">Account Name*</span>
                  </label>
                </div>

                <div className="bankaccinputchild">
                  <input
                    className="form-control"
                    placeholder="Add Account Name"
                    name="name"
                    value={formData.name}
                    onChange={handleInputChange}
                    id="firstname"
                    type="text"
                  />
                  {errors.name && (
                    <div className="text-danger mb-4">{errors.name}</div>
                  )}
                </div>
              </div>

              <div className="row pt-3 d-flex align-items-center">
                <div className="col-md-2">
                  <label className="form-label required">
                    <span className="text-muted">Account Code</span>
                  </label>
                </div>
                <div className="bankaccinputchild">
                  <input
                    className="form-control"
                    placeholder="Add Account Code"
                    value={formData.accountCode}
                    name="accountCode"
                    onChange={handleInputChange}
                    type="text"
                  />
                </div>
              </div>

              <div className="row pt-3 d-flex align-items-center">
                <div className="col-md-2">
                  <label className="form-label required">
                    <span className="text-muted">
                      Currency <span className="valid"> *</span>
                    </span>
                  </label>
                </div>
                <div className="">
                  <Select
                    className=""
                    options={currencyOptions}
                    value={currencyOptions.find(option => option.value === formData.currency)}
                    onChange={handleCurrencyChange}
                    placeholder="Select Currency"
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        backgroundColor: "#F6F6F6",
                        borderRadius: "8px",
                        padding: "4px",
                        height: "50px",
                        width: "330px",
                        marginTop: "10px",
                        marginLeft: "19px",

                      }),
                    }}
                  />
                </div>
              </div>

              <div className="row pt-3 d-flex align-items-center">
                <div className="col-md-2">
                  <label className="form-label required mg-b-0">
                    <span className="text-muted">Account Number</span>
                  </label>
                </div>
                <div className="bankaccinputchild">
                  <input
                    className="form-control"
                    placeholder="Add Account Number"
                    value={formData.accountNumber}
                    name="accountNumber"
                    onChange={handleInputChange}
                    type="text"
                  />
                </div>
              </div>

              <div className="row pt-3 d-flex align-items-center">
                <div className="col-md-2">
                  <label className="form-label">
                    <span className="text-dashed-underline">
                      <span className="text-muted">Bank Name</span>
                    </span>
                  </label>
                </div>
                <div className="bankaccinputchild">
                  <input
                    className="form-control"
                    placeholder="Add Bank Name"
                    name="bankName"
                    value={formData.bankName}
                    onChange={handleInputChange}
                    type="text"
                  />
                </div>
              </div>

              <div className="row pt-3 d-flex align-items-center">
                <div className="col-md-2">
                  <label className="form-label required">
                    <span className="text-muted">IFSC</span>
                  </label>
                </div>
                <div className="bankaccinputchild">
                  <input
                    className="form-control"
                    placeholder="Add IFSE"
                    value={formData.ifsc}
                    name="ifsc"
                    onChange={handleInputChange}
                    type="text"
                  />
                </div>
              </div>
              <div className="row pt-3 d-flex align-items-center">
                <div className="col-md-2">
                  <label className="form-label required">
                    <span className="text-muted">Branch Name</span>
                  </label>
                </div>
                <div className="bankaccinputchild">
                  <input
                    className="form-control"
                    placeholder="Add Branch Name"
                    value={formData.branchName}
                    name="branchName"
                    onChange={handleInputChange}
                    type="text"
                  />
                </div>
              </div>
              <div className="row pt-3 d-flex align-items-center">
                <div className="col-md-2">
                  <label className="form-label required mg-b-0">
                    <span className="text-muted">Description</span>
                  </label>
                </div>
                <div className="col-lg-7 pl-0" style={{ marginLeft: "19px" }}>
                  <textarea
                    rows="3"
                    cols="20"
                    placeholder="Description"
                    class="form-control description-textarea"
                    value={formData.description}
                    name="description"
                    onChange={handleInputChange}
                  ></textarea>
                </div>
              </div>

              <div>
                {/* {(formData.primaryAccount === "YES" || updatedChecked === true) && ( */}
                  <div class="col-lg-6 offset-lg-2 row pt-2 pl-2">
                    <div class="form-check d-flex align-items-center">
                      <input
                        id="a59745579"
                        class="ember-checkbox ember-view form-check-input"
                        type="checkbox"
                        name="primaryAccount"
                        checked={formData.primaryAccount === "YES"}
                        onChange={handleCheckboxChange}
                      />{" "}
                      <label for="a59745579" class="form-check-label">
                        Make this primary
                      </label>
                    </div>
                  </div>
                {/* )} */}
              </div>

              <div>
                {formData.primaryAccount === "YES" && (
                  <div className="row pt-3 d-flex align-items-center">
                    <div className="col-md-2">
                      <label className="form-label required mg-b-0">
                        <span className="text-muted">Enter UPI Id</span>
                      </label>
                    </div>
                    <div className="bankaccinputchild">
                      <input
                        className="form-control"
                        placeholder="Enter Your UPI Id"
                        value={formData.upiId}
                        name="upiId"
                        onChange={handleInputChange}
                        type="text"
                      />
                    </div>
                  </div>
                )}
              </div>


              <div className="text-right bottom-buttons">
                <button className="btn-1 btn mt-0" onClick={handleCancleClick}>
                  Cancel
                </button>
                <button
                  type="button"
                  className="btn-3 btn btn-primary"
                  onClick={sendData}
                >
                  Update
                </button>
              </div>
            </div>
          )}
          {/* Credit Card */}
          {formData.accountType === "Credit-Card" && (
            <div>
              <div className="row pt-3 d-flex align-items-center">
                <div className="col-md-2">
                  <label className="form-label">
                    <span className="text-muted">Account Name*</span>
                  </label>
                </div>

                <div className="bankaccinputchild">
                  <input
                    className="form-control"
                    placeholder="Account Name"
                    name="name"
                    value={formData.name}
                    onChange={handleInputChange}
                    type="text"
                  />
                  {errors.name && (
                    <div className="text-danger mb-4">{errors.name}</div>
                  )}
                </div>
              </div>

              <div className="row pt-3 d-flex align-items-center">
                <div className="col-md-2">
                  <label className="form-label required mg-b-0">
                    <span className="text-muted">Account Code</span>
                  </label>
                </div>
                <div className="bankaccinputchild">
                  <input
                    className="form-control"
                    placeholder="Account Code"
                    name="accountCode"
                    value={formData.accountCode}
                    onChange={handleInputChange}
                    type="text"
                  />
                </div>
              </div>

              <div className="row pt-3 d-flex align-items-center">
                <div className="col-md-2">
                  <label className="form-label required">
                    <span className="text-muted">
                      Currency <span className="valid"> *</span>
                    </span>
                  </label>
                </div>
                <div className="">
                  <Select
                    className=""
                    options={currencyOptions}
                    value={currencyOptions.find(option => option.value === formData.currency)}
                    onChange={handleCurrencyChange}
                    placeholder="Select Currency"
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        backgroundColor: "#F6F6F6",
                        borderRadius: "8px",
                        padding: "4px",
                        height: "50px",
                        width: "330px",
                        marginTop: "10px",
                        marginLeft: "19px",

                      }),
                    }}
                  />
                </div>
              </div>

              <div className="row pt-3 d-flex align-items-center">
                <div className="col-md-2">
                  <label className="form-label required mg-b-0">
                    <span className="text-muted">Bank Name</span>
                  </label>
                </div>
                <div className="bankaccinputchild">
                  <input
                    className="form-control"
                    placeholder="Bank Name"
                    name="bankName"
                    value={formData.bankName}
                    onChange={handleInputChange}
                    type="text"
                  />
                </div>
              </div>
              <div className="row pt-3 d-flex align-items-center">
                <div className="col-md-2">
                  <label className="form-label required">
                    {" "}
                    <span className="text-muted">Description</span>
                  </label>
                </div>
                <div className="col-lg-7 pl-0" style={{ marginLeft: "19px" }}>
                  <textarea
                    rows="3"
                    cols="20"
                    name="description"
                    value={formData.description}
                    onChange={handleInputChange}
                    placeholder="Max. 500 characters"
                    id="ember786"
                    class="description-textarea form-control"
                  ></textarea>
                </div>
              </div>
              <div className="text-right bottom-buttons">
                <button className="btn-1 btn mt-0" onClick={handleCancleClick}>
                  Cancel
                </button>
                <button
                  type="button"
                  className="btn-3 btn btn-primary"
                  onClick={sendData}
                >
                  Update
                </button>
              </div>
              {/* <div className="my-2 text-right">
                <button className="btn btn-primary px-2 py-1" style={{ backgroundColor: "var(--primary-color)" }} onClick={sendData}>
                  Update
                  {"  "}
                  <i className="fa fa-save"></i>
                </button>
                <button className="btn btn-secondary mx-2 px-2 py-1" onClick={handleCancleClick}>
                  Cancel <i className="fa fa-times"></i>
                </button>
              </div> */}
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default BankUpdate;
